import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import moment from 'moment-timezone';


const estados = [
    "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG",
    "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR",
    "RS", "SC", "SE", "SP", "TO"
];

Modal.setAppElement('#root'); // Evita alertas de acessibilidade

function App() {
    const [seccional1, setSeccional1] = useState('ES');
    const [seccional2, setSeccional2] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [pagina, setPagina] = useState(1);
    const [advogado1, setAdvogado1] = useState('');
    const [advogado2, setAdvogado2] = useState('');
    const [oab1, setOab1] = useState('');
    const [oab2, setOab2] = useState('');
    const [quantidade, setQuantidade] = useState('1');
    const [nomePreso, setNomePreso] = useState('');
    const [emailadv, setEmailadv] = useState('');
    const [imagemProcuracao, setImagemProcuracao] = useState(null);
    const [imagemOab, setImagemOab] = useState(null);
    const [imagemProcuracao2, setImagemProcuracao2] = useState(null);
    const [imagemOab2, setImagemOab2] = useState(null);
    const [imagemOab3, setImagemOab3] = useState(null);
    const [imagemOab4, setImagemOab4] = useState(null);
    const [movimentoData, setMovimentoData] = useState([]);
    const [movimentoHorario, setMovimentoHorario] = useState([]);
    const [dataAgendada, setDataAgendada] = useState('');
    const [horarioAgendado, setHorarioAgendado] = useState('');
    const [idDia , setIdDia] = useState('')
    const [link1 , setLink1] = useState('')
    const [link2 , setLink2] = useState('')
    const [senha1 , setSenha1] = useState('')
    const [senha2 , setSenha2] = useState('')
    const [dataLinks, setDataLinks] = useState([]);
    const [captcha, setCaptcha] = useState('');
    const [inputCaptcha, setInputCaptcha] = useState('');
    const [captchaValid, setCaptchaValid] = useState(false);


    useEffect(() => {
        gerarCaptcha();
    }, []);

    const gerarCaptcha = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Gera apenas letras maiúsculas e números
        let captcha = '';
        for (let i = 0; i < 4; i++) {
            captcha += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        setCaptcha(captcha.toUpperCase()); // Garante que o CAPTCHA esteja em maiúsculas
    };
    
    useEffect(() => {
        setCaptchaValid(inputCaptcha.trim().toUpperCase() === captcha.trim().toUpperCase());
    }, [inputCaptcha]);

    

    const validarCaptcha = () => {
        setCaptchaValid(inputCaptcha.trim() === captcha.trim());
    };

    const handleCaptchaError = () => {
        gerarCaptcha(); // Gera um novo CAPTCHA
        setInputCaptcha(''); // Limpa o campo de entrada do CAPTCHA
        alert("CAPTCHA incorreto. Tente novamente.");
    };

    useEffect(() => {
        axios.get('https://alertadiarioes.com/apipavir/get_links.php')
          .then(response => {
            const firstItem = response.data[0]; // Acessa o primeiro item do array
      
            setDataLinks(response.data);
            setLink1(firstItem.link1);
            setLink2(firstItem.link2);
            setSenha1(firstItem.senha1);
            setSenha2(firstItem.senha2);
            setLoading(false);
      
            console.log('link1 ' + firstItem.link1);
            console.log('link2 ' + firstItem.link2);
            console.log('link1 ' + firstItem.senha1);
            console.log('link2 ' + firstItem.senha2);
          })
          .catch(error => {
            setLoading(false);
            console.error("Erro ao buscar os links:", error);
          });
      }, []);
      
    

   function reset() {
    setPagina(1)
    setAdvogado1('')
    setAdvogado2('')
    setLoading(false)
    setOab1('')
    setOab2('')
    setDataAgendada('')
    setEmailadv('')
    setImagemOab(null)
    setImagemProcuracao(null)
    setImagemOab2(null)
    setImagemOab3(null)
    setImagemOab4(null)
    setImagemProcuracao2(null)
    setNomePreso('')
    setQuantidade(1)
    
    
   }

   const dataHorarioSaoPaulo = moment.tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss');

    

    const enviarDados = async () => {

        
        setLoading(true)
        const formData = new FormData();
        formData.append('advogado1', advogado1);
        formData.append('oab1', oab1);
        formData.append('advogado2', advogado2);
        formData.append('oab2', oab2);
        formData.append('nomePreso', nomePreso);
        formData.append('emailadv', emailadv);
        formData.append('dataAgendada', dataAgendada);
        formData.append('horarioAgendado', horarioAgendado);
        formData.append('seccional1', seccional1);
        formData.append('seccional2', seccional2);
        formData.append('dataHoraLocal', dataHorarioSaoPaulo); // Adiciona a data e hora local
        formData.append('link1', link1); // Adiciona a data e hora local
        formData.append('link2', link2); // Adiciona a data e hora local
        formData.append('senha1', senha1); // Adiciona a data e hora local
        formData.append('senha2', senha2); // Adiciona a data e hora local

    
        if (imagemProcuracao) {
            formData.append('imagemProcuracao', imagemProcuracao, `procuracao-${advogado1}-${dataAgendada}`);
        }
    
        if (imagemOab) {
            formData.append('imagemOab', imagemOab, `oab-${advogado1}-${dataAgendada}`);
        }
        if (imagemProcuracao2) {
            formData.append('imagemProcuracao2', imagemProcuracao2, `procuracao2`);
        }
    
        if (imagemOab2) {
            formData.append('imagemOab2', imagemOab2, `oab2`);
        }
        if (imagemOab3) {
            formData.append('imagemOab3', imagemOab3, `oab3`);
        }
        if (imagemOab4) {
            formData.append('imagemOab4', imagemOab4, `oab4`);
        }
    
        try {
            const response = await axios.post('https://alertadiarioes.com/apipavir/api_agendamento.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Dados enviados com sucesso:', response.data);
            setPagina(4)
            setLoading(false);
        } catch (error) {
            console.error('Erro ao enviar dados:', error);
            setPagina(5)
            setLoading(false);
        }
    };


    useEffect(() => {
        const fetchMovimento = async () => {
            try {
                const response = await axios.get('https://alertadiarioes.com/apipavir/get_movimento_pavir.php');
                
                // Assegure-se de que a resposta é um array
                const data = Array.isArray(response.data) ? response.data : [];
                
                // Logs para depuração
                console.log('Dados recebidos:', data);
    
                // Filtrar as datas
                const today = new Date();
                const threeDaysFromToday = new Date(today);
                threeDaysFromToday.setDate(today.getDate() + 3); // Define três dias após hoje
    
                console.log('Data de hoje:', today);
                console.log('Data de três dias a partir de hoje:', threeDaysFromToday);
    
                const filteredData = data.filter(item => {
                    // Supondo que item.desc_dia seja uma string no formato 'dd/mm/yyyy'
                    const [day, month, year] = item.desc_dia.split('/').map(Number);
                    const itemDate = new Date(year, month - 1, day); // Cria a data no formato correto
    
                    console.log('Data do item:', itemDate);
    
                    // Verifica se a data do item está dentro do intervalo desejado
                    return itemDate >= today ;
                });
    
                console.log('Dados filtrados:', filteredData);
                setMovimentoData(filteredData);
            } catch (error) {
                console.error('Erro ao buscar os dados do movimento:', error);
            }
        };
    
        fetchMovimento();
    }, []);
    
    
    

    const fetchMovimentoHorario = async () => {
        try {
            const response = await axios.get(`https://alertadiarioes.com/apipavir/get_horario_pavir.php?id_dia=${dataAgendada}`, {
                params: {
                    data: dataAgendada // Adiciona o parâmetro de consulta aqui
                }
            });
            
            // Assegure-se de que a resposta é um array
            const data = Array.isArray(response.data) ? response.data : [];
            setMovimentoHorario(data);
            console.log('Movimento horario:', data);
        } catch (error) {
            console.error('Erro ao buscar os dados do movimento:', error);
        }
    };

    useEffect(() => {
        fetchMovimentoHorario();
    }, [dataAgendada]);

    const isFirstButtonDisabled = () => {
        return loading || !(advogado1.trim() !== '' && oab1.trim() !== '');
    };
    const isFirstButton2Disabled = () => {
        return loading || !(advogado2.trim() !== '' && oab2.trim() !== '' && imagemOab2 !== null && imagemProcuracao2 !== null);
    };

    const isSecondButtonDisabled = () => {
        return !(nomePreso.trim() !== '' && emailadv.trim() !== '' && imagemOab !== null && imagemProcuracao !== null);
    };

    const isThirdButtonDisabled = () => {
        // Corrigindo a lógica do botão para considerar o CAPTCHA corretamente
        return loading || !captchaValid || !inputCaptcha || !dataAgendada || !horarioAgendado;
    };
    

    return (
        <div className="App">
            <Header />

            <main className="main-content">
                {pagina === 1 && <>
                <div className='form-group'>
                <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="advogado1">UNIDADE PRISIONAL</label>

                <input
                            id="advogado1"
                            value='CDPG - CENTRO DE DETENÇÃO PROVIÓRIA DE GUARAPARI'
                            
                            type="text"
                            
                            className="input-numeral"
                            style={{fontSize:12 }}
                            disabled
                            
                        />
                </div>
                    <div className="form-group">
                    
                   
                        
                        
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="advogado1">ADVOGADO</label>
                        
                        <input
                            id="advogado1"
                            value={advogado1}
                            onChange={(e) => {
                                const filteredValue = e.target.value.replace(/[^a-zA-Z\s]/g, '').toUpperCase();
                                setAdvogado1(filteredValue);
                            }}
                            type="text"
                            
                            className="input-numeral"
                            
                            required
                        />
                       
                    </div>
                    <div className="form-group">
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="oab1">OAB</label>
                        <input
                            id="oab1"
                            value={oab1}
                            onChange={(e) => setOab1(e.target.value)}
                            type="number"
                        
                            className="input-numeral"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="seccional">SECCIONAL</label>
                        <select
                            value={seccional1}
                            onChange={e => setSeccional1(e.target.value)}
                            id="seccional"
                            className="select-estados"
                        >
                            {estados.map((estado, index) => (
                                <option key={index} value={estado}>{estado}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="quantidade">QUANTIDADE</label>
                        <select
                            value={quantidade}
                            onChange={e => setQuantidade(e.target.value)}
                            id="quantidade"
                            className="select-estados"
                        >
                            <option value="1">1 ADVOGADO</option>
                            <option value="2">2 ADVOGADOS</option>
                        </select>
                    </div>
                    <br />
                    <div className="form-group">
                        <button
                            className={`btn-prosseguir ${isFirstButtonDisabled() ? 'disabled' : 'enabled'}`}
                            disabled={isFirstButtonDisabled()}
                            onClick={() => setPagina(2)}
                        >
                            {loading ? 'Carregando...' : 'Prosseguir'}
                        </button>
                    </div>
                </>}



                {pagina === 6 && <>
                    <div className="form-group">
                        
                    <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="advogado1">ADVOGADO 2:</label>
                        <input
                            id="advogado1"
                            value={advogado2}
                            onChange={(e) => {
                                const filteredValue = e.target.value.replace(/[^a-zA-Z\s]/g, '').toUpperCase();
                                setAdvogado2(filteredValue);
                            }}
                            type="text"
                            placeholder=""
                            className="input-numeral"
                            required
                        />
                    </div>
                    <div className="form-group">
                    <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="oab1">OAB:</label>
                        <input
                            id="oab1"
                            value={oab2}
                            onChange={(e) => setOab2(e.target.value)}
                            type="number"
                            placeholder=""
                            className="input-numeral"
                            required
                        />
                    </div>

                    <div className="form-group">
                    <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="seccional">SECCIONAL:</label>
                        <select
                            value={seccional2}
                            onChange={e => setSeccional2(e.target.value)}
                            id="seccional"
                            className="select-estados"
                        >
                            {estados.map((estado, index) => (
                                <option key={index} value={estado}>{estado}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                    <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="imagemOab2">CARTEIRA OAB (FRENTE)</label>
                            <input
                                type="file"
                                id="imagemOab2"
                                className="input-numeral"
                                accept="image/*"
                                onChange={(e) => setImagemOab2(e.target.files[0])}
                                required
                            />
                        </div>
                    <div className="form-group">
                    <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="imagemOab4">CARTEIRA OAB (VERSO)</label>
                            <input
                                type="file"
                                id="imagemOab4"
                                className="input-numeral"
                                accept="image/*"
                                onChange={(e) => setImagemOab4(e.target.files[0])}
                                required
                            />
                        </div>
                   
                        <div className="form-group">
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="imagemProcuracao">PROCURAÇÃO DO INTERNO</label>
                            <input
                                type="file"
                                id="imagemProcuracao"
                                className="input-numeral"
                                accept="image/*"
                                onChange={(e) => setImagemProcuracao2(e.target.files[0])}
                                required
                            />
                        </div>
                    <br />
                    <div className="form-group">
                        <button
                            className={`btn-prosseguir ${isFirstButton2Disabled() ? 'disabled' : 'enabled'}`}
                            disabled={isFirstButton2Disabled()}
                            onClick={() => setPagina(3)}
                        >
                            {loading ? 'Carregando...' : 'Prosseguir'}
                        </button>
                    </div>
                </>}

                {error && <p className="error-message">{error}</p>}

                {pagina === 2 && (
                    <form>
                       
                       

                        <div className="form-group">
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="email">E-MAIL PARA CONTATO</label>
                            <input
                                type="email"
                                id="email"
                                className="input-numeral"
                                placeholder="Email do Advogado"
                                value={emailadv}
                                onChange={(e) => setEmailadv(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="imagemOab">CARTEIRA OAB (FRENTE)</label>
                            <input
                                type="file"
                                id="imagemOab"
                                className="input-numeral"
                                accept="image/*"
                                onChange={(e) => setImagemOab(e.target.files[0])}
                                required
                            />
                        </div>
                        <div className="form-group">
                    <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="imagemOab3">CARTEIRA OAB (VERSO)</label>
                            <input
                                type="file"
                                id="imagemOab3"
                                className="input-numeral"
                                accept="image/*"
                                onChange={(e) => setImagemOab3(e.target.files[0])}
                                required
                            />
                        </div>
                        <div className="form-group">
                        
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="nomepreso">INTERNO</label>
                        <input
                            id="preso_atendido"
                            value={nomePreso}
                            onChange={(e) => {
                                const filteredValue = e.target.value.replace(/[^a-zA-Z\s]/g, '').toUpperCase();
                                setNomePreso(filteredValue);
                            }}
                            type="text"
                            placeholder="Nome do Interno"
                            className="input-numeral"
                            required
                        />
                    </div>
                        <div className="form-group">
                        <label style={{fontSize:14, fontWeight:'bold'}} htmlFor="imagemProcuracao">PROCURAÇÃO DO INTERNO</label>
                            <input
                                type="file"
                                id="imagemProcuracao"
                                className="input-numeral"
                                accept="image/*"
                                onChange={(e) => setImagemProcuracao(e.target.files[0])}
                                required
                            />
                        </div>
                        <button
                            className={`btn-prosseguir ${isSecondButtonDisabled() ? 'disabled' : 'enabled'}`}
                            disabled={isSecondButtonDisabled()}
                            onClick={() => {
                                // Supondo que `quantidade` é o valor de `vezes_agendado`
                                if (quantidade == '2') {
                                    setPagina(6);
                                } else {
                                    setPagina(3);
                                }
                            }}
                        >
                            {loading ? 'Carregando...' : 'Prosseguir'}
                        </button>
                    </form>
                )}

                {pagina === 3 && (
                    <>
                        <form>
                            <div className="form-group">
                                <label htmlFor="dataAgendada">DATA</label>
                                <select value={dataAgendada}
                                    onChange={e => {setDataAgendada(e.target.value)}}
                                    id="dataAgendada"
                                    className="select-estados2">
            <option value="">-</option> {/* Adiciona a opção vazia */}
            {movimentoData.length > 0 ? (
                movimentoData
                    .slice() // Cria uma cópia do array
                    .reverse() // Inverte a cópia
                    .map((item, index) => (
                        <option key={index} value={item.desc_dia}>
                            {item.desc_dia}
                        </option>
                    ))
            ) : (
                <option value="">Nenhuma opção disponível</option>
            )}
        </select>
                            </div>
<br></br>
                            <div className="form-group">
                                <label htmlFor="horarioAgendado">HORÁRIO</label>
                                <select
    value={horarioAgendado}
    onChange={e => setHorarioAgendado(e.target.value)}
    id="horarioAgendado"
    className="select-estados2"
>
    <option value="">-</option> {/* Adiciona a opção vazia */}

    {movimentoHorario
        .slice() // Cria uma cópia do array
        .reverse() // Inverte a cópia
        .map((item, index) => (
            <option key={index} value={item.desc_horario}>
                {item.desc_horario}
            </option>
        ))
    }
</select>

                            </div>

                            <div className="form-group">
                    <label htmlFor="captcha">CAPTCHA</label>
                    <div className="captcha-container">
                        <span className="captcha">{captcha}</span>
                        <button type="button" onClick={gerarCaptcha} className="btn-refresh-captcha">
                            Atualizar
                        </button>
                    </div>
                    <input
                        type="text"
                        value={inputCaptcha}
                        onChange={e => {
                            setInputCaptcha(e.target.value.toUpperCase());
                            validarCaptcha();
                        }}
                        id="captcha"
                        className="input-numeral"
                    />
                    {!captchaValid && inputCaptcha.length == 4 && inputCaptcha && <p className="error">CAPTCHA incorreto.</p>}
                </div>
                <br />
                        </form>
                        <br></br>
                        <div className="form-group">
                        <button
    className={`btn-prosseguir ${isThirdButtonDisabled() ? 'disabled' : 'enabled'}`}
    disabled={isThirdButtonDisabled()}
    onClick={() => {
        validarCaptcha(); // Valida o CAPTCHA antes de prosseguir
        if (captchaValid) {
            enviarDados() // Prossegue para a próxima página apenas se o CAPTCHA for válido
        }
    }}
>
    {loading ? 'Carregando...' : 'Prosseguir'}
</button>
        </div>
                    </>
                )}

{pagina === 4 && (
                   <form>
                   <div className="form-group success-alert">
                       <h3 className="main-text">AGENDAMENTO EFETUADO COM SUCESSO</h3>
                       <div className="left-align-text">
                          
                           <h4>Agendamento para {dataAgendada} de {horarioAgendado.replace("-", "às")}, os detalhes da reunião foram enviados para o edereço de e-mail cadastrado no formulário de agendamento.</h4>
                       </div>
                   </div>
                   <button
                       className={`btn-prosseguir ${isSecondButtonDisabled() ? 'disabled' : 'enabled'}`}
                       disabled={isSecondButtonDisabled()}
                       onClick={() =>reset()}
                   >
                       {loading ? 'Carregando...' : 'Voltar'}
                   </button>
               </form>
               
                
                )}
{pagina === 5 && (
                   <form>
                   <div className="form-group erro-alert">
                       <h2 className="main-text">ALGO DEU ERRADO!</h2>
                       <div style={{color:'white'}}>
                          <h3>Efetue seu agendamento novamente.</h3>
                           
                       </div>
                   </div>
                   <button
                       className={`btn-prosseguir`}
                       style={{backgroundColor:'gray'}}
                       onClick={() =>reset()}
                   >
                       {loading ? 'Carregando...' : 'Voltar'}
                   </button>
               </form>
               
                
                )}
            </main>

            <Footer />
        </div>
    );
}

export default App;
