import React from 'react';
import './Footer.css';
import ImagemSejus from '../assets/img/sejus.png'


const Footer = () => {
    return (
        <footer className="footer" style={{ boxShadow: '8px 4px 8px rgba(0, 0, 0, 0.1)' }}>
           <img style={{height:70}}src={ImagemSejus} ></img>
        </footer>
    );
};

export default Footer;
