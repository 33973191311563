import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <header className="header" style={{ boxShadow: '8px 4px 8px rgba(0, 0, 0, 0.1)' }}>
           <h3 className="site-title">PARLATÓRIO VIRTUAL</h3>


        </header>
      
    );
};

export default Header;